$main-color: #323a45;

.stream-status {
  width: 30px;
  height: 25px;
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}

.dropdown-body {
  background-color: #1f262e !important;
  opacity: 0.9;
  padding: 10px 0px 10px 0px;
  color: white;
  margin-top: 5px;
  max-width: 550px;

  .dropdown-body-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 5px 0 5px;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
    border-bottom: 1px #454d55;
    line-height: 18px;
  }

  .dropdown-element-container {
    max-height: 200px;
    overflow-y: scroll;
    font-size: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .spinner-container {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dropdown-element {
      display: flex;
      font-size: 1.2em;
      width: 550px;
      padding: 3px 3px 3px 3px;
      border-color: #454d55;
      background-color: #1f262ee6 !important;
      justify-content: space-between;
      line-height: 1;
      font-weight: normal;
      font-style: normal;
      font-family: Arial, Helvetica, sans-serif;
      word-break: initial;

      &:nth-child(2) {
        margin-top: 30px;
      }

      &:nth-child(2n + 1) {
        background-color: rgb(69, 77, 85) !important;
      }

      div {
        align-self: center;
      }

      .buttons {
        display: flex;

        button {
          padding: 2px;
          background-color: transparent;
          border-radius: 5px;
          margin-left: 5px;
          float: right;
          height: fit-content;
          width: fit-content;
          border: none;

          img {
            width: 30px;
            height: 20px;

            &.disabled {
              filter: brightness(0.5);
            }
          }
        }
      }
    }
  }
}

.close-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  margin: 0;
  height: 100%;
}
