.selection-window__marker {
  cursor: pointer;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: transparent;
  transition: all 0.2s ease-out;
  &.start {
    left: -35px;
    z-index: 8;
  }
  &.end {
    right: -35px;
    z-index: 8;
  }

  .arrow-block {
    width: 85%;
    height: 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;

    & > img {
      width: 50%;
      height: 100%;
      &:first-of-type {
        transform: rotate(180deg);
      }
    }
  }

  &.active-right {
    background: linear-gradient(to right, #ffb700, rgba(255, 222, 0, 0));
  }
  &.active-left {
    background: linear-gradient(to right, rgba(255, 222, 0, 0), #ffb700);
  }
}
