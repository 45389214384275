$dark-color: #1a1f25;

.toggle-btn {
    display: block;
    height: 60px;
    position: absolute;
    width: 60px;
    border-radius: 50%;
    border: none;
    color: white;
    background-color: $dark-color;
    outline: none;
    padding-top: 25px;
    z-index: 6;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);

    .expand-with-toggle {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 22.5px;
        top: 15px;
        height: 50px;

        & > i {
            padding-bottom: 5px;
        }
    }

    &-expanded{
        top: calc(100vh - 30px);
        display: block;
        height: 30px;
        position: absolute;
        width: 60px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        border: none;
        color: white;
        background-color: $dark-color;
        outline: none;
        z-index: 4;
        left: 50%;
        transform: translateX(-50%);
    }
}

.toggle-btn-hover {
    &:hover {
        top: -10px;
    }
}
