.sidebar-menu {
    width: 15%;
    min-width: 15%;
    background-color: #303641
}

.page-container {
    display: flex;
    height: fit-content;
    min-height: 100%;
}

.logo-header {
    width: 100%;
    padding: 25px;
}

.ui.grid {
    margin-left: 0px !important;
}

.logo {
    display: block;
    background-image: url('../../assets/stats_logo.png');
    background-repeat:no-repeat;
    background-size:contain;
    height: 70px;
}

.sidebar-menu {
    font-size: 14pt;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    height: 90%;
    color: white;
    width: 260px;
    border-radius: 10px;
    background-color: #323A45;
    
}

.menu-item {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    position: relative;
    border-bottom: 1px solid rgba(69, 74, 84, 0.7);
    color: white;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 10px;
    margin-left: 15px;
    & p {
        margin: 5px;
    }
    & i {
        margin: 5px;
    }
}

.main-content {
    background-color: #F1F1F1;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}
.main-menu {
    padding-inline-start: 0px;
}
.logo-wrapper {
    height: 100%;
    width: 100%;
}
