.multicast-modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.64);
  position: absolute;
  z-index: 100;
  overflow: hidden;

  .multicast-modal-container {
    position: absolute;
    width: 1090px;
    height: 580px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    background-color: #393E46;
    font-family: "Poppins", sans-serif;
    color: white;

    .multicast-loader-overlay {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-header {
    display: flex;
    justify-content: flex-start;
    height: 50px;
    background-color: #363940;
    border-radius: 9px 9px 0 0;
    padding: 0;
    border: none;

    &__logo {
      width: 55px;
      margin-top: 6px;
      margin-left: 40px;
    }

    &__box-number {
      font-size: 11px;
      font-weight: bold;
      margin-top: 9px;
      margin-left: 6px;
      text-transform: uppercase;
    }

    &__close-button {
      width: 17px;
      margin-left: auto;
      margin-top: 10px;
      margin-right: 10px;
    }
  }

  .modal-body {
    display: flex;
    height: 530px;
    padding: 0;

    .locations {
      min-width: 225px;
      background-color: #5A6983;
      height: 100%;
      border-radius: 0 0 0 9px;

      &__title {
        font-size: 24px;
        line-height: 53px;
        font-weight: 500;
        text-align: center;
        height: 55px;
        margin: 0;
      }

      &__list {
        list-style: none;
        padding: 0;
        height: 475px;
        overflow-y: auto;
      }

      &__list-item {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        padding: 13px 13px 13px 23px;
        cursor: pointer;
        background-color: #5A6983;

        &:hover {
          background-color: #6F84A5;
        }

        &.active {
          background-color: #393E46;

          & > span:nth-child(2) {
            background-image: url("../../assets/screen-blue.svg");
          }
        }
      }

      &__location-name {
        font-size: 17px;
        width: 135px;
      }

      &__screen-icon {
        width: 18px;
        height: 13px;
        margin-right: 10px;
        margin-top: 5px;
        border: none;
        background-image: url("../../assets/screen.svg");
      }

      &__screen-quantity {
        font-size: 17px;
      }
    }

    .screens {
      padding-left: 5px;
      padding-right: 28px;

      &__header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 55px;
        padding-left: 25px;
        padding-top: 18px;

        .send-to-all-title {
          font-size: 15px;
          margin-right: 7px;
        }

        .send-to-all-button {
          width: 36px;
          height: 18px;
          border-radius: 18px;
          border: 1px #5A6983 solid;
          background-color: #363940;
          padding: 1px;
          transition: 0.3s all;
          cursor: pointer;

          &.active {
            border: 1px #0C3C24 solid;
            background-color: #2BC779;
            transition: 0.3s all;

            & > div {
              margin-left: 18px;
              border: 1px #0C3C24 solid;
            }
          }

          .send-to-all-circle {
            width: 14px;
            height: 14px;
            border-radius: 7px;
            border: 1px #3b3b3b solid;
            background-color: white;
          }
        }
      }

      &__table {
        display: flex;
        height: 410px;
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow-y: auto;
      }
    }
  }
}

::-webkit-scrollbar-track {
  background: #5A6983;
}

