.events-progress-bar-container {
    position: absolute;
    bottom: 40px;
    height: 100px;
    width: 100%;
    background: rgba(43,51,63, 0.7);
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .timeline {
        width: calc(100% - 200px);
        margin-left: 100px;
        margin-right: 100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pause {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50px;
            position: 'relative';
            border-left: 2px solid white;
            border-right: 2px solid white;
            margin-left: 10px;
            margin-right: 10px;
            height: 100%;
        }
        
        span {
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 15px
        }

        .timeline-section {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .icon {
                cursor: pointer;
                position: absolute;
                img {
                    height: 20px;
                    width: 20px;
                }
            }
            
            p {
                position: absolute;
                left: -9px;
                top: -20px;
                font-size: 18px;
                &.time0 {
                    left: -4px;
                }
            }
            .line {
                width: 2px;
                background: lightcyan;
                height: 10px;
                &.long {
                    height: 30px;
                }
            }
        }

    }
}