.clip-controls {
  position: relative;
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 5px;
}

.timeline {
  position: relative;
  width: 90vw;
  height: 150px;
  background: #464646;
  display: flex;
  justify-content: center;
}

.selection-window {
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  position: absolute;
  border: 8px solid #ffde00;
  border-left: 35px solid #ffde00;
  border-right: 35px solid #ffde00;
  z-index: 3;
  :focus {
    outline: none;
  }

  &__drag-panel {
    position: absolute;
    width: 100px;
    height: 100%;
    top: -20;
    left: 50%;
    transform: translateX(-50%);
    background: #ffde00;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
    z-index: 5;
    cursor: grab;
    & > img {
      cursor: pointer;
      width: 25px;
      height: 25px;
      &:first-of-type {
        transform: rotate(180deg);
      }
    }
  }
  &__time-mark {
    position: absolute;
    color: white;
    top: -28px;
    left: -5px;
    font-size: 13px;
    transition: 0.2s all;
  }
}

.selection-window.active {
  transition: all 0.3s ease-in-out;
  z-index: 3;
  border-color: #ffb700;
  & > div {
    background-color: #ffb700;
  }
}

.frames-wrapper {
  width: 94%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1a1a1a;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.frame {
  width: 14.3%;
  height: 100%;
  cursor: pointer;
  &.first-colored {
    &:first-child {
      opacity: 1;
    }
    &:last-child {
      opacity: 0.3;
    }
  }
  &.last-colored {
    &:first-child {
      opacity: 0.3;
    }
    &:last-child {
      opacity: 1;
    }
  }
  &:hover {
    transform: scale(0.90);
    transition: 0.2s all ease-in-out;
    box-shadow: rgba(13, 26, 31, 0.12) 0 2px 4px 0, rgba(8, 17, 21, 0.32) 0 2px 16px 0;
  }
  &:first-child, &:last-child {
    opacity: 0.3;
    &:hover {
      transform: scale(1);
      box-shadow: none;
    }
  }
}

.clip-confirm-button {
  position: absolute;
  width: 25%;
  right: 10px;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
  border: none;
  background-color: #FF3466;
  color: white;
  font-size: 15px;
  padding: 5px 12px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #941c39;
  }
  &:active {
    background-color: #e59dae
  }
}

::selection {
  background: transparent;
}

::-moz-selection {
  background: transparent;
}