$main-color: #323a45;
$dark-color: #1a1f25;

.opacity-0 {
    opacity: 0!important
}

.header-icon{
    color: white;
    opacity: 0.9;
    font-size: 25px;
}

#header {
    position: fixed;
}

.doc-toggle {
    background-color: transparent!important;
    border-color: transparent!important;
    margin: 0 5px;
    height: 100%;
    &:focus{
       box-shadow: none!important; 
    }
}

div.profile-dropdown.dropdown-menu.show {
    left: -120px !important;
    width: 90px;
    font-size: 15px!important;
    margin: 0!important;
    text-align: center !important;
    padding: 15px 5px 10px 5px !important;
}

.doc-dropdown-button {
    cursor: pointer;
}

.profile-dropdown-item {
    cursor: pointer;
    max-height: 60px;
    margin-bottom: 15px;
}

.doc-dropdown{
    min-width: 80px!important; 
    padding: 0!important;  
    font-size: 15px!important;
    right: -5px!important;
    left: auto!important;
    margin: 0!important;
}


.doc-dropdown-button{
    color: white;
    background: transparent;
    border: none;
    width: 100%;    
    padding: 0 8px!important;    
}

.doc-dropdown-item{    
    padding: 0!important;
    padding-right: 20px;
    height: 35px;
    display: flex!important;
    align-items: center;
    &:hover{
        background-color: #0000006b!important;
    }
    a:hover{
        color: inherit;
    }
}

.header-cntr {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 5px;
}

.cntr-container {
    display: flex;
    justify-content: end;

    .show.dropdown {
        position: relative !important;
    }
}

.header-container {
    width: 100%;
    position: absolute;
    height: 60px;
    background-color: $main-color;
    display: flex;
    color: white;
    z-index: 5;
    opacity: 0;
    top: 0;
    justify-content: space-between;

    .main-logo {
        width: 140px;
        object-fit: contain  
    }

    .header-block {
        cursor: pointer;
        display: flex;
        margin-left: 60px;
    }
}

.hoverable {
    z-index: 2;

    &:hover {
        opacity: 1!important;
    }
}

.show {
    opacity: 1;
}

.SingleDatePickerInput {
    background-color: $main-color!important;
    border: none!important;
    height: 48px!important;

    .SingleDatePicker_picker {
        z-index: 1000!important;
    }
    .SingleDatePickerInput_calendarIcon {
        margin: 5px 5px 0 5px!important;
    }
    .DateInput {
        display: none!important;
    }
}

.webflow-style-input {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 400px;
    max-width: 400px;
    border-radius: 2px;
    padding: 10px;
    background: rgb(26, 31, 37);
    height: 50px;
    margin: 5px 5px 5px 5px;
}

.webflow-style-input input {
    border-style: none;
    background: transparent;
    outline: none;
    flex-grow: 1;
    color: white;
    font-size: 1rem;
    line-height: 1.4rem;
    vertical-align: middle;
    padding-left: 10px;
}

.webflow-style-input input::-webkit-input-placeholder {
    color: #98a0ab;
}

#jump-live {
    background-color: transparent;
    border: none;
    outline: none;

    &:focus {
        box-shadow: none;
    }

    & img {
        height: 25px;
        width: 25px;
        opacity: 0.7;
    }
}

#jump-live:active {
    transform: translateY(2px);
    transition-duration: 200ms;
}

.advanced-filter-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.d-flex {
    display: flex;
}

#filters-outside, #filters-outside > div {
    display: inherit;
}

.support-modal {
    .modal-content {
        background-color: #1b1d21!important; //#1a1f25!important;
        color: white!important;
        border: 1px solid gainsboro!important;
        button {
            color: white;
        }
    }
}

.support-modal-info {
    line-height: 35px;
}

.diagnostic-toaster-icon *{
    background-color: green!important;
}

.stb-section {
    width: 100%;
    position: relative;
    margin-top: 65px;
    z-index: 3;
}