.stream-item {
  position: relative;
  width: 242px;
  height: 120px;
  border: 1px solid #606060;
  border-radius: 9px;
  background-color: #1D2229;
  margin: 12px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 3;

  &__title {
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.3px;
    text-transform: uppercase;
    color: white;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 175px;
  }

  &__btn {
    width: 49px;
    height: 36px;
    background-color: #1D2229;
    border: none;
    transition: 0.2s all ease-in-out;
  }

  &__multicast-btn {
    background: url("../../../../assets/skybox-icons/multicast.svg") center center/cover no-repeat;

    &:hover {
      background: url("../../../../assets/skybox-icons/multicast-hover.svg") center center/cover no-repeat;
    }
  }

  &__edit-btn {
    background: url("../../../../assets/skybox-icons/edit.svg") center center/cover no-repeat;

    &:hover {
      background: url("../../../../assets/skybox-icons/edit-hover.svg") center center/cover no-repeat;
    }
  }

  &__delete-btn {
    background: url("../../../../assets/skybox-icons/trash.png") center center/cover no-repeat;

    &:hover {
      filter: brightness(1.6);
    }
  }
}
