.add-stream-modal {
  width: 533px;
  height: 373px;
  box-shadow: 0 0 6px 2px rgba(35, 35, 35, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border: 3px solid #606060;
  border-radius: 28px;
  background-color: #1D2229;
  padding: 28px;
  text-align: left;

  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }

  &__title {
    font-family: Poppins, sans-serif;
    width: 100%;
    font-weight: 300;
    font-size: 26px;
    color: white;
  }

  &__form {
    margin-top: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > label {
      margin-top: 3px;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 300;
      color: white;

      & > input {
        font-family: Poppins, sans-serif;
        margin-top: 5px;
        width: 100%;
        height: 48px;
        border-radius: 6px;
        border: none;
        color: black;
        padding: 4px 12px;
      }
    }
  }

  &__error {
    height: 11px;
    font-size: 11px;
    line-height: 11px;
    color: #f13838;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 5px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;
  }

  &__btn {
    border: none;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    text-align: left;
    padding-left: 15px;
    color: white;
    font-weight: 300;
  }

  &__cancel-btn {
    width: 131px;
    height: 42px;
    background-color: #525862;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: #B92C2C;
    }
  }

  &__add-btn {
    width: 109px;
    height: 42px;
    margin-left: 12px;
    background-color: #396DC6;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: #10369D;
    }
  }
}
