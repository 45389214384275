.change-pwd-container {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    height: calc(85vh);
    align-items: center;
}

.change-password-expanded {
    opacity: 1 !important;
}

.metismenu {
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    overflow: hidden;
    position: relative;
    height: 90%;
    color: white;
    width: 260px;
    border-radius: 10px;
    background-color: #323A45;
}
