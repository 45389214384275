.redux-toastr .toastr.rrt-message {
    background-color: #29c9b3 !important;
    height: fit-content;
    border-radius: 5px;
    transition: all 0.5s ease-out;
    border: 1px solid white !important;
}

/*.redux-toastr .toastr.rrt-message:hover {
    max-height: 300px;
}*/

.redux-toastr .toastr.rrt-message:hover > div > .rrt-text {
    max-height: fit-content;
}

.redux-toastr .toastr.rrt-message .rrt-text {
    font-size: 1.0em !important;
    transition: all 0.5s ease-out;
    border: 0;
    padding: 12px 17px 12px 17px !important;
    background-color: white !important;
    margin-bottom: 10px !important;
}

.redux-toastr .toastr.rrt-message .rrt-title {
    text-align: start !important;
    max-height: 35px;
    font-weight: 500;
    line-height: 35px !important;
    font-size: 1.1em !important;
    color: white;
}
.redux-toastr .toastr.rrt-message .close-toastr span {
    display:none;
}

.redux-toastr .toastr.rrt-message .close-toastr {
    height: 40px;

    &:before {
        content: url("../../assets/close.svg");
    }
}

.details-container > div {
    margin-top: 5px;
    margin-bottom: 5px;
    & span {
        font-weight: 500;
    }
}

.country-icon-container {
    display: flex;
    justify-content: flex-start;
}

.country-icon {
    margin: 4px !important;
    margin-left: 0 !important;
    height: 17px;
    width: 26px;
    margin-top: 7px !important;
}

.details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}




