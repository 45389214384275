.video-js .vjs-picture-in-picture-control {
    display: none;
}

.vjs-error .vjs-error-display:before {
    content: none;
}

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
   padding-top: 40vh;
}

.vjs-volume-control.vjs-control.vjs-volume-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
} 

.vjs-control-bar {
    display: flex;
    justify-content: center;
}

.event-dropdown {
    position: absolute;
}
.video-js.vjs-v6 .vjs-seek-button.skip-forward .vjs-icon-placeholder::before, .video-js.vjs-v7 .vjs-seek-button.skip-forward .vjs-icon-placeholder::before {
    content: "";
}

.video-js .vjs-seek-button.skip-forward.skip-30::before, .video-js.vjs-v6 .vjs-seek-button.skip-forward.skip-30 .vjs-icon-placeholder::before, .video-js.vjs-v7 .vjs-seek-button.skip-forward.skip-30 .vjs-icon-placeholder::before {
    content: "";
}

.video-js.vjs-v6 .vjs-seek-button.skip-back .vjs-icon-placeholder::before, .video-js.vjs-v7 .vjs-seek-button.skip-back .vjs-icon-placeholder::before {
    content: "";
}

.video-js .vjs-seek-button.skip-back.skip-30::before, .video-js.vjs-v6 .vjs-seek-button.skip-back.skip-30 .vjs-icon-placeholder::before, .video-js.vjs-v7 .vjs-seek-button.skip-back.skip-30 .vjs-icon-placeholder::before {
    content: "";
}

.vjs-seek-button.vjs-control.vjs-button {
    max-width: 40px;
    margin-left: 5px;
    margin-right: 5px;
} 

.vjs-control-bar, .custom-controls {
    height: 40px !important;
}

.skip-forward.skip-30 .vjs-icon-placeholder::before {
    background-image: url('../../assets/Forward30.png')!important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    content: "";
}

.fullscreen-button > i::before {
    font-size: 14px;
}

.live-icon {
    height: 60px;
    width: 180px;
    position: absolute;
    top: 40px;
    right: 40px;
    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .live-label {
            font-size: 20px;
            color: white;
            margin-bottom: 0;
        }
        .dot {
            width: 20px;
            height: 20px;
        }
    }

}

.vjs-playing > span::before,
.vjs-paused > span::before,
.vjs-vol-0 > span::before,
.vjs-vol-1 > span::before,
.vjs-vol-2 > span::before,
.vjs-vol-3 > span::before,
.vjs-fullscreen-control > span::before,
.video-js .vjs-volume-panel .vjs-volume-control,
.vjs-playback-rate-value
{
    padding-top: 5px !important;
}

.fa-chevron-left::before,
.fa-chevron-right::before,
.fa-angle-double-left::before,
.vjs-icon-placeholder::before,
.vjs-play-contro > span::before {
    font-size: 15px;
}

.fullscreenButton > i::before,
.volume-icon > i::before {
    font-size: 16px;
}
.custom-controls .volume-container .volume .rangeslider-horizontal {
    height: 4px;
}


.skip-forward.skip-7 .vjs-icon-placeholder::before {
    background-image: url('../../assets/Forward7.png')!important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    content: "";
}

.skip-forward.skip-2 .vjs-icon-placeholder::before {
    background-image: url('../../assets/Forward2.png')!important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    content: "";
}

.skip-back.skip-30 .vjs-icon-placeholder::before {
    background-image: url('../../assets/Rewind30.png')!important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    content: "";
}

.skip-back.skip-7 .vjs-icon-placeholder::before {
    background-image: url('../../assets/Rewind7.png')!important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    content: "";
}

.skip-back.skip-2 .vjs-icon-placeholder::before {
    background-image: url('../../assets/Rewind2.png')!important;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    content: "";
}

.action-buttons {
    position: absolute;
    bottom: 40px;
    height: 100px;

    background: rgba(36,85,163, 0.75);
    opacity: 1;
    display: flex;
    padding: 3px;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    i {
        font-size: 18px
    }
    button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(220,220,220, 0.3);
            border-radius: 100%;
            height: 4em;
            width: 4em;
            border: 2px solid darkgrey;
            margin: 5px 0;
    }
    &.blue {
        background-color: #192E5B;
            svg {
                color: white;
            }
        svg {
            height: 60%;
            width: 60%;
            color: rgba(224,226,228, 0.7);
            padding: 3px;
        }
        &:hover {
            transform: scale(1.03);
        }
        &:active {
            background: #192E5B;
            border-radius: 100%;
            div {
                color: white;
            }    
        }
    }
}

.vjs-marker {
    &:before {
        background-size: 20px 20px;
        height: 20px;
        width: 20px;
        display: inline-block;
        position: absolute;
        top: -20px;
        left: -10px;
    }
}

.skybox-options-icons {
    position: absolute;
    right: 50px;
    z-index: 1;

    & > div {
        width: 71px;
        height: 71px;
        margin-top: 17px;
    }
}

.multicast-icon {
    background-image: url(../../assets/skybox-icons/multicast.svg);

    &:hover {
        background-image: url(../../assets/skybox-icons/multicast-hover.svg);
    }
}

.remote-icon {
    background-image: url(../../assets/skybox-icons/remote.svg);

    &:hover {
        background-image: url(../../assets/skybox-icons/remote-hover.svg);
    }
}

.eCode1070, .eCode2094, .eCode1227, .eCode2056 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/red-card-png-3.png');
    }
}

.eCode1068, .eCode2092, .eCode1034, .eCode2058 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/yellow-card-png-4.png');
    }
}
.eCode1025, .eCode2049 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/corner.png');
    }
}

.eCode1065, .eCode2089, .eCode1029, .eCode2053 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/goal.png');
    }
}

.eCode11, .eCode10, .eCode3, .eCode13, .eCode1029, .eCode2053, .eCode4, .eCode5, .eCode6, .eCode7, .eCode14, .eCode15, .eCode16, .eCode17 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/kickoff_finalwhistle.png');
    }
}

.eCode1043, .eCode2067 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/offside.png');
    }
}
.eCode1031, .eCode2055 {
    &:before {
        content: "";
        background-image: url('../../assets/rb-icons/penalty.png');
    }
}

.video-js .vjs-play-progress {
    background-color: red;
}

.clip-button {
    position: absolute;
    bottom: 40px;
    right: 20px;
    width: 75px;
    height: 62px;
    & > img {
        width: 100%;
        height: 100%;
    }
}

