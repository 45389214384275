.event-dropdown {
    position: absolute;
}

.player-container {
    position: relative;
    overflow: hidden;

    video {
        background-color: black;
    }
}

// Hover for controls on the video
.web-rtc-player:hover + .custom-controls  {
    opacity: 1;
}

.custom-controls {
    background-color: rgba(43,51,63, 0.7);
    height: 30px;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.8em;
	-webkit-transition: opacity 0.5s;
	-moz-transition: opacity 0.5s;
	-o-transition: opacity 0.5s;
	-ms-transition: opacity 0.5s;
    transition: opacity 0.5s;

    opacity: 0;
    &:hover {
        opacity: 1;
    }

    .fullscreenButton {
        align-self: center;
        cursor: pointer;
    }
    
    .volume-container {
        display: flex;
        margin-left: 20px;
        align-items: center;
        
        .volume-icon {

            & i {
                font-size: 15px;
                width: 25px;
                align-self: center;
            }
        }


        .volume {
            width: 100px;
            margin-left: 10px;
            background-color: transparent;
    
            .rangeslider-horizontal {
                height: 3px;
            }

            .rangeslider {
                margin: 0;
                background-color: rgba(115, 133, 159, 0.75);
            }
    
            .rangeslider__handle {
                width: 9px;
                height: 9px;
                left: -13px;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 0 1px 1px #333;
            }
    
            .rangeslider__fill {
                background-color: white;
            }
    
            .rangeslider-horizontal .rangeslider__handle:after {
                content: none;
            }
            
        }
    }
}

// Hide cotrols in fullscreen
::-webkit-media-controls {
    display:none !important;
}

// #2B333F
// 3em