.vod-loading-icon {
    margin-right: 10px;
    font-size: 13px;
    color: white;
}

.rc-tooltip-inner {
    span {
        font-size: 14px;
    }
}

.toastr.animated.rrt-light {
    background-color: rgba(54, 54, 55, 0.9) ;
    color: white;
    max-height: 60px;
    border-radius: 5px;
    transition: all 0.5s ease-out;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.start-time {
    text-align: left;
    position: relative;
    min-width: 165px;
    width: 165px;

    span {
        position: relative;
        margin-left: 20px;

        i {
            margin-right: 10px;
            cursor: pointer;
            position: absolute;
            left: -25px;
            top: 2px;
        }
    }
}

.competition-column {
    max-width: 17vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.information-column {
    max-width: 12vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-team-column, .away-team-column {
    max-width: 17vw;
    white-space: nowrap;
}

.latency {
    min-width: 160px;
    text-align: center;
    white-space: nowrap;
    padding: 0.5px 12px!important;
    cursor: pointer;

    img {
        height: 26px;
    }
}


