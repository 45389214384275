.sldp-container {
  max-width: 100vw !important;
  max-height: 100vh !important;
  position: relative;
  z-index: 1;
}

.event-dropdown {
  position: absolute;
}

.sldp-options-icons {
  position: absolute;
  right: 50px;
  bottom: 60px;
  z-index: 100;
  height: 159px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    width: 71px;
    height: 71px;
  }
}

.multicast-icon {
  background-image: url(../../assets/skybox-icons/multicast-player.svg);

  &:hover {
    background-image: url(../../assets/skybox-icons/multicast-player-hover.svg);
  }
}

.remote-icon {
  background-image: url(../../assets/skybox-icons/remote.svg);

  &:hover {
    background-image: url(../../assets/skybox-icons/remote-hover.svg);
  }
}

.live-icon {
  height: 60px;
  width: 180px;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 1;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .live-label {
      font-size: 20px;
      color: white;
      margin-bottom: 0;
    }

    .dot {
      width: 20px;
      height: 20px;
    }
  }
}
