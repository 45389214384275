.clipper__underlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.clipper__container {
  width: 95vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: black;
  border: 2px solid #ffde00;
  position: relative;
}

.clip-vod-video {
  width: 75%;
}

.clipper__close-button {
  width: 25px;
  height: 25px;
  padding: 0;
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  & > img {
    height: 100%;
  }
}

.vjs-error {
  width: 850px;
  height: 420px;
}