.sky-box {

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 100;
    border-radius: 9px;
    text-align: center;
    padding-top: 43px;
  }

  .not-available-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    padding-top: 43px;
    z-index: 100;
    border-radius: 9px;
    backdrop-filter: blur(2px);
  }

  display: flex;
  position: relative;
  justify-content: space-between;
  max-width: 276px;
  width: 100%;
  min-height: 120px;
  height: 90px;
  background-color: #191D20;
  border-radius: 9px;
  margin-top: 15px;
  margin-left: 25px;
  padding-left: 10px;
  padding-top: 4px;
  padding-right: 6px;
  padding-bottom: 6px;
  border: 1px solid #707070;
}

.sky-box-left-side {
  width: 40%;
}

.box-name-and-screens-panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.box-title {
  display: flex;
  flex-direction: column;
  &__logo {
    margin-top: -3px;
  }
}

.box-number {
  font-size: 11px;
  font-weight: bold;
  line-height: 10px;
  margin-bottom: 8px;
  color: white;
}

.box-screens-panels {
  width: 49px;
  height: 36px;
  border: 1px solid #5A6983;
  border-radius: 3px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &__screen-icon {
    width: 18px;
    height: 11px;
    background: #396DC6;
    border-radius: 1px;
    position: relative;
    transition: .2s;

    &:before {
      content: "";
      height: 1px;
      width: 10px;
      background-color: white;
      border-radius: 1px;
      position: absolute;
      bottom: -2px;
      left: 4px;
    }
  }

  .center-screen-icon {
    margin: 4px auto;
  }
}

.box-screens-panels:hover > .box-screens-panels__screen-icon {
  background-color: #98AAC9;
  transition: .2s;
}

.screens-panels-icon {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1f25;
  position: absolute;
  right: 1px;
  bottom: 3px;
}

.screens-panels-icon-img {
  height: 6px;
  width: 6px;
  display: block;
  transition: .2s;
}

.box-user-info {
  display: flex;
  align-items: center;

  &__img-box {
    width: 29px;
    height: 29px;
    background: transparent;
    border-radius: 50%;
    position: relative;
  }

  &__user-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

  }

  &__status {
    position: absolute;
    right: -5px;
    bottom: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #ffffff;
    background: #8A9B93;
    border-radius: 50%;
  }

  &__status_active {
    background: #2BC779;
  }

  &__status-text {
    font-size: 12px;
    max-width: 55px;
    word-wrap: anywhere;
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 10px;
    line-height: 12px;
    color: white;
  }
}

.sky-box-right-side {
  display: flex;
}







