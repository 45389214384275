$text-color: white;
$main-color: #323a45;
$dark-color: #1a1f25;
$dark-details-color: #3b424c;

.multiselect {
  width: 100%;
  margin: 0;
  position: relative;

  .multiselect-arrow {
    position: absolute;
    top: 18px;
    right: 15px;
    font-size: 17px;
    color: $dark-details-color;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &.open {
      top: 74px;
    }

    &:hover {
      transform: translateY(3px);
    }
    // content: "🡇";
  }
}

.category-search-icon {
  position: absolute;
  left: 25px;
  top: 20px;
}

input.category-input {
  width: 100%;
  padding: 10px;
  padding-left: 25px;
  min-height: 57px;
  margin: 0;
  display: inline-block;
  border: none;
  border-bottom: 1px solid $dark-details-color;
  box-sizing: border-box;
  background-color: $dark-color;
  cursor: pointer;

  &.live-search {
    padding-left: 50px;
    cursor: text;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #98a0ab !important;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #98a0ab !important;
    }
  }
}

/*::placeholder { !* Chrome, Firefox, Opera, Safari 10.1+ *!
    color: $text-color!important;
    opacity: 1; !* Firefox *!
}

:-ms-input-placeholder { !* Internet Explorer 10-11 *!
    color: $text-color!important;
}

::-ms-input-placeholder { !* Microsoft Edge *!
    color: $text-color!important;
}*/

.multiselect-body {
  position: absolute;
  width: calc(100%); // Magic number = padding for 
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  transition: all 0.3s;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
} 

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.inputGroup {
  background-color: $dark-details-color;
  display: block;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $main-color;
  }

  label {
    padding: 13px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: white;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    margin: 0;
    transition: color 200ms ease-in;
    overflow: hidden;
    border-bottom: 1px solid $dark-color;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 20px;
      height: 20px;
      content: '';
      border: 1px solid #D1D7DC;
      background-color: #fff;
      border-radius: 2px;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    background-color: $main-color;
    font-weight: 600;
    color: #FF3466;
    transition: all 0.2s;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: $dark-color;
      border: none;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23EA4969' fill-rule='nonzero'/%3E%3C/svg%3E ");
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    color: #FF3466;
  }
}
