.remote {
  position: absolute;
  right: 2vw;
  bottom: 1.5vh;
  color: white;
  z-index: 100;

  .channel-pad {
    width: 9.5vw;
    height: 6.5vh;
    background-color: #323232;
    border: 1px #707070 solid;
    border-radius: 1vh;
    opacity: 0.78;
    margin: 0 auto;
    margin-bottom: 1.5vh;
    font-size: 1.7vw;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 6.5vh;
  }

  .remote-btn {
    background-color: #545454;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid rgba(112, 112, 112, 0.85);
    border-radius: 0.8vh;
    color: white;

    &:hover {
      background-color: #646464;
    }
  }

  &-container {
    width: 14.9vw;
    height: 89vh;
    background-color: #323232;
    border: 1px #707070 solid;
    border-radius: 2vh;
    opacity: 0.78;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    padding: 0.8vw;

    .upper-block {
      height: 10vh;
      position: relative;
      display: flex;

      &__close-img {
        width: 2.5vh;
        height: 2.5vh;
      }

      &__tv-btn {
        width: 2.3vw;
        height: 2.3vw;
        font-size: 0.8vw;
        margin-left: 0.3vw;
        margin-bottom: 2vh;
        align-self: flex-end;
      }

      &__indicator {
        position: absolute;
        top: 1vh;
        left: 50%;
        transform: translateX(-50%);
        width: 2vh;
        height: 2vh;
        border-radius: 1vh;
        background-color: #9B2323;
        box-shadow: inset 0 1px 2px #00000029;
        border: 2px solid #4B4C47;

        &.active {
          background-color: #fc8989;
          box-shadow: inset 0 0 4px 2px #de1414;
        }
      }

      &__sky-btn {
        width: 4.1vw;
        height: 4.5vh;
        margin-left: 0.7vw;
        align-self: flex-end;

        & > img {
          width: 4.5vh;
        }
      }

      &__switch-btn {
        width: 1.8vw;
        height: 1.8vw;
        border-radius: 0.9vw;
        padding: 0;
        padding-bottom: 0.15vw;
        align-self: flex-start;
        margin-top: 2.3vh;
        margin-left: 1vw;

        & > img {
          width: 0.9vw;
        }
      }
    }

    .menu {
      height: 12vh;
      padding-top: 2vh;
      font-size: 0.6vw;
      font-family: "Poppins", sans-serif;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      &__box-office-btn {
        width: 4.1vw;
        height: 3.6vh;
        margin-left: 1.8vw;
      }

      &__services-btn {
        width: 4.1vw;
        height: 3.6vh;
        margin-left: 1.4vw;
      }

      &__tv-guide-btn {
        width: 3.9vw;
        height: 3.4vh;
        margin-top: 0.7vh;
      }

      &__interactive-btn {
        width: 3.9vw;
        height: 3.4vh;
        margin-left: 5.3vw;
        margin-top: 0.7vh;
        margin-bottom: 3vh;
      }

      &__round-btn {
        position: absolute;
        width: 3.4vh;
        height: 3.4vh;
        border-radius: 1.7vh;
        padding: 0;
      }

      &__mute-btn {
        top: 9.2vh;
        left: 4.2vw;
      }

      &__info-btn {
        top: 9.2vh;
        right: 4.2vw;
      }
    }

    .controls {
      height: 8.7vw;
      padding-top: 1vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &__vertical-btn {
        position: relative;
        background-color: #545454;
        width: 2vw;
        height: 9vh;
        border: 1px solid rgba(112, 112, 112, 0.85);
        text-align: center;
        line-height: 8.8vh;
        font-size: 1.2vh;
      }

      &__round-btn {
        width: 2vw;
        height: 2vw;
        border-radius: 1vw;
        background-color: #6b6b6b;
        font-size: 1.8vh;
        line-height: 1.8vw;
        border: 1px solid rgba(126, 126, 126, 0.85);
      }

      &__vertical-upper-btn {
        position: absolute;
        top: -1.7vh;
        right: -0.05vw;
      }

      &__vertical-lower-btn {
        position: absolute;
        bottom: -1.7vh;
        right: -0.05vw;
      }

      &__central-pad {
        width: 5.5vw;
        height: 5.5vw;
        border-radius: 0.5vw;
        background-color: #545454;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &__corner-btn {
        width: 2.2vw;
        height: 2.2vw;
        border-radius: 1.1vw;
        border: none;
        box-shadow: none;
        background-color: #545454;
        position: absolute;
      }

      &__up-btn {
        left: 50%;
        transform: translateX(-50%);
        top: 0.5vw;

        & > img {
          position: absolute;
          right: 0.59vw;
          bottom: 1vw;
          width: 1vw;
        }
      }

      &__left-btn {
        left: 2.5vw;

        & > img {
          transform: rotate(-90deg);
          position: absolute;
          bottom: 0.8vw;
          right: 0.9vw;
          width: 1vw;
        }
      }

      &__right-btn {
        right: 2.5vw;

        & > img {
          transform: rotate(90deg);
          position: absolute;
          bottom: 0.8vw;
          left: 0.9vw;
          width: 1vw;
        }
      }

      &__down-btn {
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        & > img {
          transform: rotate(180deg);
          position: absolute;
          right: 0.59vw;
          top: 1vw;
          width: 1vw;
        }
      }

      &__select-btn {
        position: absolute;
        left: 50%;
        top: 3.2vw;
        transform: translateX(-50%);
        width: 2.7vw;
        height: 2.7vw;
        border-radius: 1.35vw;
        background-color: #6b6b6b;
        font-size: 1.2vh;
        border: 1px solid rgba(126, 126, 126, 0.85);
      }
    }

    .player-menu {
      height: 17vh;
      display: flex;
      flex-wrap: wrap;
      padding: 1vh 1.5vw 0.9vh 1.5vw ;
      position: relative;
      font-size: 1.2vh;

      &__round-btn {
        width: 1.7vw;
        height: 1.7vw;
        border-radius: 0.85vw;
        padding: 0;
      }

      &__back-up-btn {
        width: 3.2vw;
        height: 3.5vh;
        margin-top: 0.5vh;
        margin-left: 1.77vw;
        padding: 0;
      }

      &__help-btn {
        margin-left: 1.77vw;
      }

      &__back-btn {
        width: 1.9vw;
        height: 5.5vh;
        border-radius: 1.4vh;
        margin-top: 1.1vh;
        padding: 0;
        padding-right: 0.25vw;

        & > img {
          width: 1.2vw;
        }
      }

      &__big-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 0.92vw;
        margin-top: 0.8vh;
      }

      &__pause-btn {
        width: 4.5vw;
        height: 3vh;
        border-radius: 1.5vh 1.5vh 0 0;

        & > img {

          width: 0.5vw;
          &:nth-child(1) {
            margin-right: 0.1vw;
          }

          &:nth-child(2) {
            margin-left: 0.1vw;
          }
        }
      }

      &__play-btn {
        width: 4.5vw;
        height: 4vh;
        border-radius: 0 0 4vh 4vh;
        padding-left: 0.5vw;

        & > img {
          width: 1vw;
        }
      }

      &__forward-btn {
        width: 1.9vw;
        height: 5.5vh;
        border-radius: 1.4vh;
        margin-left: 0.92vw;
        margin-top: 1.1vh;
        padding: 0;
        padding-left: 0.25vw;

        & > img {
          transform: rotate(180deg);
          width: 1.2vw;
        }
      }

      &__record-btn {
        margin-left: 1.8vw;
        padding: 0.25vw;

        .record-circle {
          width: 1.1vw;
          height: 1.1vw;
          border-radius: 0.55vw;
          border: 1px white solid;
          background-color: #B52200;
          line-height: 2vh;
        }
      }

      &__stop-btn {
        margin-left: 3.1vw;
        padding: 0.45vw;

        .stop-square {
          width: 0.7vw;
          height: 0.7vw;
          border-radius: 0.1vw;
          background-color: white;
        }
      }
    }

    .colored-pad {
      height: 3vh;
      display: flex;
      justify-content: space-between;
      padding: 0.5vh 2vw;

      .colored-btn {
        width: 1.7vw;
        height: 1.7vw;
        border: 1px white solid;
        border-radius: 0.85vw;
      }

      &__red-btn {
        background-color: #DB5755;
      }

      &__green-btn {
        background-color: #10B1AA;
      }

      &__yellow-btn {
        background-color: #E7D063;
      }

      &__blue-btn {
        background-color: #3C71A3;
      }
    }

    .numeric-keypad {
      padding: 1.2vh 1.9vw 0 1.7vw;

      &__btn {
        width: 2.3vw;
        height: 2.3vw;
        border-radius: 1.15vw;
        margin-left: 0.7vw;
        margin-top: 1.3vh;

        &:first-child > div:nth-child(2) {
          color: rgba(255, 255, 255, 0);
        }

        .number {
          font-size: 1vw;
          line-height: 2.3vh;
        }

        .alphabet {
          font-size: 0.5vw;
          line-height: 1.2vh;
        }

        &:last-child {
          margin-left: 3.7vw;
          line-height: 1vh;

          & > img {
            width: 0.4vw;
            display: inline-block;
          }
        }
      }
    }
  }
}

.remote-small {
  position: absolute;
  right: 1vw;
  top: 0.75vh;
  color: white;
  z-index: 100;

  .channel-pad {
    width: 4.75vw;
    height: 3.25vh;
    background-color: #323232;
    border: 1px #707070 solid;
    border-radius: 0.5vh;
    opacity: 0.78;
    margin: 0 auto;
    margin-bottom: 0.75vh;
    font-size: 1vw;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 3.25vh;
  }

  .remote-btn {
    background-color: #545454;
    box-shadow: 0 2px 3px #00000029;
    border: 1px solid rgba(112, 112, 112, 0.85);
    border-radius: 0.4vh;
    color: white;

    &:hover {
      background-color: #646464;
    }
  }

  &-container {
    width: 7.45vw;
    height: 44.5vh;
    background-color: #323232;
    border: 1px #707070 solid;
    border-radius: 1vh;
    opacity: 0.78;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    padding: 0.4vw;

    .upper-block {
      height: 5vh;
      position: relative;
      display: flex;

      &__close-img {
        width: 1.25vh;
        height: 1.25vh;
      }

      &__tv-btn {
        width: 1.15vw;
        height: 1.15vw;
        font-size: 0.4vw;
        margin-left: 0.15vw;
        margin-bottom: 1vh;
        align-self: flex-end;
      }

      &__indicator {
        position: absolute;
        top: 0.5vh;
        left: 50%;
        transform: translateX(-50%);
        width: 1vh;
        height: 1vh;
        border-radius: 0.5vh;
        background-color: #9B2323;
        box-shadow: inset 0 1px 1px #00000029;
        border: 1px solid #4B4C47;

        &.active {
          background-color: #fc8989;
          box-shadow: inset 0 0 2px 1px #de1414;
        }
      }

      &__sky-btn {
        width: 2.05vw;
        height: 2.25vh;
        margin-left: 0.35vw;
        align-self: flex-end;

        & > img {
          width: 2.25vh;
          margin-bottom: 0.7vh;
        }
      }

      &__switch-btn {
        width: 1vw;
        height: 1vw;
        border-radius: 0.5vw;
        padding: 0;
        align-self: flex-start;
        margin-top: 1.15vh;
        margin-left: 0.5vw;

        & > img {
          width: 0.45vw;
          display: block;
          margin: 0 auto;
        }
      }
    }

    .menu {
      height: 6vh;
      padding: 0;
      padding-top: 1vh;
      font-size: 0.4vw;
      line-height: 0.4vw;
      font-family: "Poppins", sans-serif;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      &__box-office-btn {
        width: 2.2vw;
        height: 2.1vh;
        margin-left: 0.75vw;
      }

      &__services-btn {
        width: 2.2vw;
        height: 2.1vh;
        margin-left: 0.7vw;
      }

      &__tv-guide-btn {
        width: 2vw;
        height: 1.9vh;
        margin-top: 0.35vh;
      }

      &__interactive-btn {
        width: 2vw;
        height: 1.9vh;
        margin-left: 2.5vw;
        margin-top: 0.35vh;
        margin-bottom: 1.5vh;
        word-wrap: break-word;
      }

      &__round-btn {
        position: absolute;
        width: 2.1vh;
        height: 2.1vh;
        border-radius: 1.05vh;
        padding: 0;
      }

      &__mute-btn {
        top: 4.6vh;
        left: 2.1vw;
      }

      &__info-btn {
        top: 4.6vh;
        right: 2.1vw;
      }
    }

    .controls {
      height: 4.35vw;
      padding-top: 0.5vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &__vertical-btn {
        position: relative;
        background-color: #545454;
        width: 1vw;
        height: 4.5vh;
        border: 1px solid rgba(112, 112, 112, 0.85);
        text-align: center;
        line-height: 4.4vh;
        font-size:0.7vh;
      }

      &__round-btn {
        width: 1vw;
        height: 1vw;
        border-radius: 0.5vw;
        background-color: #6b6b6b;
        font-size: 0.9vh;
        line-height: 0.9vw;
        border: 1px solid rgba(126, 126, 126, 0.85);
      }

      &__vertical-upper-btn {
        position: absolute;
        top: -0.85vh;
        right: -0.025vw;
        font-size: 0.6vw;
        line-height: 0.6vw;
        padding: 0.2vw;
      }

      &__vertical-lower-btn {
        position: absolute;
        bottom: -0.85vh;
        right: -0.025vw;
        font-size: 0.6vw;
        line-height: 0.6vw;
        padding: 0.2vw;
      }

      &__central-pad {
        width: 2.75vw;
        height: 2.75vw;
        border-radius: 0.25vw;
        background-color: #545454;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &__corner-btn {
        width: 1.1vw;
        height: 1.1vw;
        border-radius: 0.55vw;
        border: none;
        box-shadow: none;
        background-color: #545454;
        position: absolute;
      }

      &__up-btn {
        left: 50%;
        transform: translateX(-50%);
        top: 0.25vw;

        & > img {
          position: absolute;
          right: 0.295vw;
          bottom: 0.5vw;
          width: 0.5vw;
        }
      }

      &__left-btn {
        left: 1.25vw;

        & > img {
          transform: rotate(-90deg);
          position: absolute;
          bottom: 0.4vw;
          right: 0.45vw;
          width: 0.5vw;
        }
      }

      &__right-btn {
        right: 1.25vw;

        & > img {
          transform: rotate(90deg);
          position: absolute;
          bottom: 0.4vw;
          left: 0.45vw;
          width: 0.5vw;
        }
      }

      &__down-btn {
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        & > img {
          transform: rotate(180deg);
          position: absolute;
          right: 0.295vw;
          top: 0.5vw;
          width: 0.5vw;
        }
      }

      &__select-btn {
        position: absolute;
        left: 50%;
        top: 1.6vw;
        transform: translateX(-50%);
        width: 1.35vw;
        height: 1.35vw;
        border-radius: 0.675vw;
        background-color: #6b6b6b;
        font-size: 0.6vh;
        border: 1px solid rgba(126, 126, 126, 0.85);
        padding: 0;
      }
    }

    .player-menu {
      height: 8.5vh;
      display: flex;
      flex-wrap: wrap;
      padding: 0.5vh 0.75vw 0.45vh 0.75vw ;
      position: relative;
      font-size: 0.6vh;

      &__round-btn {
        width: 1.2vw;
        height: 1.2vw;
        border-radius: 0.6vw;
        padding: 0;
        font-size: 0.8vh;
      }

      &__back-up-btn {
        width: 1.9vw;
        height: 1.85vh;
        margin-top: 0.25vh;
        margin-left: 0.35vw;
        font-size: 0.8vh;
        padding: 0;
      }

      &__help-btn {
        margin-left: 0.35vw;
      }

      &__back-btn {
        width: 0.95vw;
        height: 2.75vh;
        border-radius: 0.7vh;
        margin-top: 0.55vh;
        padding: 0;
        padding-right: 0.125vw;

        & > img {
          width: 0.6vw;
        }
      }

      &__big-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 0.27vw;
        margin-top: 0.4vh;
      }

      &__pause-btn {
        width: 2.6vw;
        height: 1.5vh;
        border-radius: 0.75vh 0.75vh 0 0;

        & > img {

          width: 0.2vw;
          &:nth-child(1) {
            margin-right: 0.05vw;
          }

          &:nth-child(2) {
            margin-left: 0.05vw;
          }
        }
      }

      &__play-btn {
        width: 2.6vw;
        height: 2vh;
        border-radius: 0 0 2vh 2vh;
        padding-left: 0.25vw;

        & > img {
          width: 0.4vw;
        }
      }

      &__forward-btn {
        width: 0.95vw;
        height: 2.75vh;
        border-radius: 0.7vh;
        margin-left: 0.27vw;
        margin-top: 0.55vh;
        padding: 0;
        padding-left: 0.125vw;

        & > img {
          transform: rotate(180deg);
          width: 0.6vw;
        }
      }

      &__record-btn {
        margin-left: 0.85vw;
        padding: 0.125vw;
        width: 1vw;
        height: 1vw;

        .record-circle {
          width: 0.6vw;
          height: 0.6vw;
          border-radius: 0.3vw;
          border: 1px white solid;
          background-color: #B52200;
          line-height: 1vh;
        }
      }

      &__stop-btn {
        margin-left: 1.4vw;
        padding: 0.275vw;
        width: 1vw;
        height: 1vw;

        .stop-square {
          width: 0.35vw;
          height: 0.35vw;
          border-radius: 0.05vw;
          background-color: white;
        }
      }
    }

    .colored-pad {
      height: 1.5vh;
      display: flex;
      justify-content: space-between;
      padding: 0.35vh 1vw;

      .colored-btn {
        width: 0.85vw;
        height: 0.85vw;
        border: 1px white solid;
        border-radius: 0.425vw;
      }

      &__red-btn {
        background-color: #DB5755;
      }

      &__green-btn {
        background-color: #10B1AA;
      }

      &__yellow-btn {
        background-color: #E7D063;
      }

      &__blue-btn {
        background-color: #3C71A3;
      }
    }

    .numeric-keypad {
      padding: 0.6vh 0.95vw 0 0.85vw;

      &__btn {
        width: 1.15vw;
        height: 1.15vw;
        border-radius: 0.575vw;
        margin-left: 0.35vw;
        margin-top: 0.65vh;

        &:first-child > div:nth-child(2) {
          color: rgba(255, 255, 255, 0);
        }

        .number {
          font-size: 0.6vw;
          line-height: 1.15vh;
        }

        .alphabet {
         display: none;
        }

        &:last-child {
          margin-left: 1.85vw;
          line-height: 0.5vh;

          & > img {
            width: 0.2vw;
            display: inline-block;
          }
        }
      }
    }
  }
}



