.change-password-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1;

  &__container {
    width: 790px;
    height: 520px;
    box-shadow: 0 0 6px 2px rgba(35, 35, 35, 0.75);
    display: flex;

    .left-side {
      width: 50%;
      background-color: white;
      padding: 20px;
      padding-top: 35px;
      text-align: center;

      &__title {
        font-size: 28px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;

        & > p {
          font-size: 34px;
          margin-bottom: 0;
        }
      }

      &__rule-text {
        font-size: 13px;
        font-family: "Montserrat", sans-serif;
      }

      &__form {
        position: relative;
        margin-top: 30px;
      }

      &__inputs {
        width: 100%;
      }

      &__label {
        display: block;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        margin-top: 20px;
        position: relative;

        i {
          width: 30px;
          font-size: 22px;
          margin-left: 5px;
          position: absolute;
          bottom: 10px;
          right: 0;
        }

        .fa-check {
          color: lightgreen;
        }
        .fa-times {
          color: lightcoral;
        }
      }

      &__input {
        width: 80%;
        font-size: 16px;
        padding: 8px;
        margin-top: 6px;
        border-radius: 21px;
        border: 1px solid grey;
        text-align: center;
      }

      &__warning {
        font-size: 14px;
        line-height: 14px;
        color: lightcoral;
      }

      &__confirm-button {
        width: 80%;
        font-size: 16px;
        padding: 9px;
        margin-top: 7px;
        border-radius: 21px;
        border: 1px solid #333945;
        background-color: #333945;
        color: white;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        position: absolute;
        top: 207px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;

        &:hover {
          background-color: white;
          color: #333945;
        }
      }
    }

    .right-side {
      width: 50%;
      background-color: #323b44;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &__logo {
        width: 320px;
      }

      &__error {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
        color: lightcoral;
      }

      &__version {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        color: white;
        top: 80%;
        font-family: "Montserrat", sans-serif;
      }
    }
  }
}


















/*
.temp {
    font-family: 'Montserrat', sans-serif;
    .reset_pass {
        width: 100%;
    }
    
    .reset_label {
        margin-bottom: 15px;
    }
    .warning {
        color: #ED1D2C !important;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 12px;
    }
    > div > div {
        font-weight: 600;
        margin-bottom: 2px;
        font-size: 16px;
    }

    .flex {
        display: flex;
        flex-direction: row;

        i {
            width: 40px;
            font-size: 26px;
            margin-left: 10px;
            &:before {
                padding-left: 15px;
            }
        }

        .fa-check {
            color: lightgreen;
        }
        .fa-times {
            color: lightcoral;
        }

    }
}

.confirm-button {
    text-align: center;
    background: #323b44;
    width: 33%;
    border: 0;
    color: white;
    border-radius: 20px;
    padding: 10px 0 10px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    outline: none;
    &:disabled {
        opacity: 0.3;
    }
}*/
