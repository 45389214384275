.form-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    height: 90%;
    max-width: 45%;
    min-width: 45%;
    padding-bottom: 30px;
    background-color: #323A45;
    margin: 20px;
    margin-left: 4px;
    border-radius: 10px;
    position: relative;

    .form-control.is-invalid, .was-validated .form-control:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        background-image: none !important;
    }

    .invalid-feedback {
        display: block !important;
    }

    .flex {
        display: flex;
        flex-direction: row;

        i {
            width: 40px;
            font-size: 26px;
            margin-left: 10px;
            &:before {
                padding-left: 15px;
            }
        }

        .fa-check {
            color: lightgreen;
        }
        .fa-times {
            color: lightcoral;
        }

    }

    & span {
        cursor: pointer;
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 30px;
        color: white;
        align-self: flex-start;
        justify-self: flex-start;
    }    

    .change_label {
        margin-bottom: 20px;
        font-size: 14px;
        width: 80%;
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
    }

    .confirm-button {
        text-align: center;
        background: white !important;
        width: 50%;
        height: 40px;
        margin-bottom: 20px;
        border: 0;
        color: #323b44 !important;
        border-radius: 20px;
        padding: 10px 0 10px 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        outline: none;
        &:disabled {
            opacity: 0.3;
        }
    }
}

form {
    width: 100%;
    text-align: center;
}

.form-check {
    margin: 10px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    & input {
        height: 20px;
        width: 20px;
    }
    & label {
        margin-left: 5px;
    }
}

.form-group {
    text-align: start;
}

.form-container-internal {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.form-label {
    font-weight: 500;
    color: white;
    margin: 5px;
}
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.form-container .fa-user {
    font-size: 6rem;
    color: white;
    margin-bottom: 10px;
}


.errors {
    margin-top: 10px;
    color: #ED1D2C !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 12px;
}

    
