.box-session-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__progress-bar {
    position: absolute;
    width: 90%;
    height: 19px;
    outline: none;
    border-style: none;
    border-radius: 0 10px 10px 0;
    background-color: #5A6983;
    display: flex;
    align-items: center;
    color: white;
    font-size: 11px;
    padding: 0;
    z-index: 5;
  }

  &__progress-bar-active {
    background-color: #396DC6;
  }

  &__btn {
    width: 120px;
    height: 19px;
    outline: none;
    border-style: none;
    border-radius: 10px;
    background-color: #5A6983;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 11px;
    padding: 0;
  }

  &__btn-icon {
    position: relative;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #525862;
    z-index: 6;
  }

  &__btn-icon-active {
    background: #10369D;
  }

  &__btn-text {
    padding-left: 3px;
    z-index: 7;
  }
}

.skybox-custom-scroll {
  width: 19px;
  height: 100%;
  border: 1px solid #5A6983;
  border-radius: 10px;
  margin-left: 2px;
  position: relative;

  &__ball {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 1px;
    left: 1px;
    background: #FFFFFF;
    transition: .2s;
  }
}