@import url("https://fonts.googleapis.com/css?family=Montserrat|Alegreya+Sans+SC|Julius+Sans+One|M+PLUS+Rounded+1c&display=swap");

.forgot-password-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;

    &__container {
        width: 480px;
        height: 480px;
        box-shadow: 0 0 6px 2px rgba(35, 35, 35, 0.75);
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    &__logo {
        width: 100%;
        height: 170px;
        background-color: #323b44;
        text-align: center;

        & > img {
          width: 135px;
            margin-top: 15px;
        }
    }

    &__title {
        font-size: 26px;
        line-height: 26px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        margin-top: 25px;
        text-align: center;

        & > p {
            font-size: 41px;
            margin-top: 20px;
        }
    }

    &__form {
        width: 360px;
        margin-top: 15px;

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: gray !important;
            opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: gray !important;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: gray !important;
        }

        .reset-input {
            display: block;
            width: 100%;
            font-size: 16px;
            padding: 8px;
            border-radius: 20px;
            font-family: "Montserrat", sans-serif;
            border: 1px solid grey;
            color: #323b44;
            text-align: center;
        }

        .reset-button {
            display: block;
            text-align: center;
            cursor: pointer;
            margin-top: 25px;
            background: #323b44;
            width: 100%;
            border: 0;
            color: white;
            border-radius: 22px;
            font-size: 16px;
            padding: 10px;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            outline: none;

            &:hover {
                background-color: white;
                color: #323b44;
                border: 1px solid #323b44;
                padding: 9px 0 9px 0;
            }
        }
    }
}
