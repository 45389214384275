.download-stream_count-label {
    position: absolute;
    color: white;
    font-size: 10px;
    right: 53px;
}

.cancelled-label {
    color: #FF3466
}

.coming-soon-label {
    color: white;
}

span img {
    width: 24px !important;
    height: 24px !important;
}

.watch-col {
    width: 8%;
    position: relative;
}

.vod-dropdown {
    & > div {

        & > div {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 15px;
            width: 252px;
            padding: 0 15px 0 15px;

            & > a {
                display: flex;
                cursor: default;
                justify-content: space-between;
                align-items: center;
                height: 48px;
                padding: 0 !important;

                & > span {
                    margin-right: auto;
                }

                &:first-child {
                    border-bottom: 1px solid #454d55;
                }

                & > div:nth-child(3) > a.disabled {
                    filter: brightness(0.5);
                }
            }
        }

        & > button {
            padding-left: 0 !important;
            background-color: transparent !important;

            &:hover {
                background: none;
            }

            &:visited {
                background: none;
            }

            & > button.play-btn {
                border: none;
            }
        }
    }
}

.vod-play-btn {
    width: 20px;
    height: 20px;
    background: black;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;

    &.disabled {
        filter: brightness(0.5);
        &:hover {
            background: black;
            cursor: default;
        }
    }

    &:hover {
        background: rgb(253, 76, 0);
    }

    &__icon {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #FFFFFF;
        border-bottom: 5px solid transparent;
        margin-left: 6px;
    }
}

.clip-vod-button {
    cursor: pointer;
    margin-left: 10px;
    & img {
        width: 28px;
        height: 28px;
    }
}