.screen {
  width: 139px;
  height: 83px;
  background-color: #566275;
  margin-left: 25px;
  margin-bottom: 25px;
  font-weight: 300;
  padding: 7px;
  position: relative;

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 100;
    text-align: center;
    padding-top: 27px;
  }

  &__id {
    position: absolute;
    font-size: 10px;
    top: 22px;
  }

  &.in-use {
    background-color: #394F74;
  }

  &__name {
    font-size: 12px;
    height: 26px;
    font-weight: 400;
    cursor: pointer;
  }

  &__state-indicator {
    position: absolute;
    top: 35px;

    .state-circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #2BC779;
      margin-right: 6px;

      &.red {
        background-color: #FD3000;
      }
    }

    .state-text {
      font-size: 11px;
      text-transform: capitalize;
    }
  }

  &__box-info {
    position: absolute;
    bottom: 7px;
    display: flex;
    align-items: center;

    .locked-icon {
      display: block;
      width: 13px;
      height: 13px;
      margin-right: 4px;
    }

    .box-number {
      display: block;
      font-size: 11px;
      margin: 0;
      margin-left: 3px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 65px;
    }
  }

  .in-use-button {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    bottom: 7px;
    right: 8px;
    width: 36px;
    height: 18px;
    border-radius: 18px;
    border: 1px white solid;
    background-color: #566275;
    padding: 1px;

    &.active {
      border: 1px #0C3C24 solid;
      background-color: #2BC779;

      & > div {
        margin-left: 18px;
        border: 1px #0C3C24 solid;
      }
    }

    .in-use-circle {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      border: 1px #B7B7B7 solid;
      background-color: white;
    }
  }
}

input.screen__input {
    font-size: 12px;
    line-height: 12px;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 135px;
    background-color: #1a1f25;
    border: none;
    margin-top: 0;
    height: 20px;
    color: white;
    padding: 6px 3px 2px 5px;
}