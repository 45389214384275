.internal-streams {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 37px;
  width: 100%;
  background-color: #323a45;
  color: white;
  cursor: pointer;
  border-bottom: 5px solid #1a1f25;
}

.internal-streams-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 45%;

  &__btn {
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1a1f25;
    margin-right: 5px;
  }

  & > p {
    font-family: Poppins, sans-serif;
    font-size: 18px;
  }

  &__btn:hover > &__btn-arrow {
    transition: .2s;
    transform: rotate(90deg);
  }

  &__btn-arrow {
    height: 16px;
    width: 16px;
    display: block;
    transition: .2s;
  }

  &__btn-arrow-active {
    height: 16px;
    width: 16px;
    display: block;
    transition: .2s;
    transform: rotate(90deg);
  }
}

.stream-items-panel {
  position: relative;
  display: flex;
  justify-content: flex-start;
  border-bottom: 5px solid #1a1f25;
  flex-wrap: wrap;
  width: 100%;
  min-height: fit-content;
  background-color: #323a45;
  padding-left: 2%;
  padding-right: 2%;

  &__add-stream {
    width: 242px;
    height: 50px;
    border: 1px solid #606060;
    border-radius: 9px;
    background-color: #1D2229;
    margin: 12px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 14px;
    padding: 0 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    color: white;

    &:hover {
      background-color: #396DC6;
      border: 1px solid #0E308C;
    }

    &:active {
      background-color: #98AAC9;
    }
  }

  .stream-items-loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    text-align: center;
    padding-top: 130px;
  }
}