$dark-color: #1a1f25;
$main-color: #323a45;
$dark-details-color: #3b424c;
$light-color: #ffffff;

.filters__container {
    position: absolute;
    margin-top: 40px;
    width: 100%;
    left: 0;
    top: 15px;
    height: fit-content;
    background-color: $dark-color;
    border-top: none;
    z-index: 1000;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.3);
}

.filters__label {
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    padding-left: 15px;
    color: $light-color;
    border-bottom: 1px solid $dark-details-color;
    background-color: $dark-details-color;
    cursor: default;
}

.filters__buttons_controls {
    display: flex;
    flex-direction: column;
}

.filters__type-label {
    display: block;
    margin-top: 17px;
    margin-left: 25px;
}

.filters__type-checkboxes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px 5px 25px;
}

.filters__buttons_actions {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    button {
        background-color: $dark-details-color;
        padding: 5px 0 5px 0!important;
        text-align: center;
        border: none;
        width: 50%;
        font-weight: 700;
        border-radius: 0;
        color: $light-color;

        &:first-child, &:nth-child(2) {
            border-right: 1px solid $dark-color;
        }

        &:hover {
            background-color: $dark-details-color !important;
            opacity: 0.9!important;
            color: $light-color;
        }

        &:active {
            background-color: #e32a5b !important;
            opacity: 0.9!important;
            border: 0!important;
        }

        &:focus {
            background-color: $dark-details-color!important;
            opacity: 0.9!important;
            border: 0!important;
        }
    }
}

