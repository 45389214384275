.devices-header {
    font-size: 34px;
    color: white;
}
.disabled-updating {
    font-size: 24px;
    color: white;
}

.devices-list {
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding-top: 5px;

    .card-title {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-danger {
        background-color: white !important;
        color: #323A45;
        width: 60%;
        height: 30px;
        border: 1px solid black;
        margin-top: 10px;

        &:hover {
            height: 31px;
            width: 61%;
            &:disabled {
                height: 30px;
                width: 60%;
                cursor: auto;
            }
        }
    }

    .card {
        width: 200px;
        margin: 10px;
        height: 200px;
        background-color: #323A45;
        border: 1px solid white;
        border-radius: 10px;
        padding: 10px;
        color: white;
        
    }

    .info-container {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .icon-container {
        display: flex;
        justify-content: center;

        i {
            font-size: 40px;
        }
    }
}