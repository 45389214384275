@import url("https://fonts.googleapis.com/css?family=Montserrat|Alegreya+Sans+SC|Julius+Sans+One|M+PLUS+Rounded+1c&display=swap");

.announcements-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;

  .announcements {
    width: 580px;
    min-height: 200px;
    max-height: 670px;
    box-shadow: 0 0 6px 2px rgba(35, 35, 35, 0.75);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-bottom: 25px;

    &__logo {
      width: 100%;
      min-height: 100px;
      background-color: #323b44;
      position: relative;
    }

    &__icon {
      color: white;
      font-size: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__header {
      font-size: 15px;
      line-height: 20px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      margin-top: 7px;
      text-decoration: underline;
      & > span {
        color: #FF3466;
        font-weight: bold;
      }
    }

    &__list {
      width: 85%;
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin-top: 10px;

      &:after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: #bcc3c9;
        margin-top: 20px;
      }

      &-title {
        font-size: 16px;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        margin: 5px 0 0 0;
        align-self: flex-start;
      }

      &-time {
        font-size: 14px;
        line-height: 16px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        margin: 5px 0 0 0;
        align-self: flex-start;
      }

      &-body {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
        margin: 12px 0 0 0;
        overflow: auto;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
        & > p {
          margin: 0;
        }
      }

      &-ok-button {
        width: 60%;
        min-height: 36px;
        display: block;
        text-align: center;
        cursor: pointer;
        margin-top: 25px;
        background: #323b44;
        border: 0;
        color: white;
        border-radius: 18px;
        font-size: 15px;
        padding: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        outline: none;

        &:hover {
          background-color: white;
          color: #323b44;
          border: 1px solid #323b44;
          padding: 9px 0 9px 0;
        }
      }
    }
  }
}









