.paywall-popup {
  position: absolute;
  font-size: 13px;
  transition: 0.2s all ease-in-out;
  height: 120px;
  width: 260px;

  &.visible {
    top: -115px;
    left: -277px;
    opacity: 1;

    &.mirrored {
      top: 40px;
    }

    &.right {
      top: -110px;
      left: 0;
      right: -10px;
    }

    &.for-event-dropdown {
      top: 415px;;
      left: 380px;
    }

    &.right.mirrored {
      top: 210px;
    }

    &.right.mirrored.vod {
      top: 120px;
    }
  }

  &.hidden {
    top: -1000px;
    left: -277px;
    opacity: 0;
    visibility: hidden;

    &.right {
      left: 0;
      right: -10px;
    }
  }

  &__main {
    padding: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    border-radius: 20px;
    background-color: #FF3466;
    border: none;
    text-align: center;
    line-height: 20px;
    box-shadow: 1px 1px 6px 6px rgba(0, 0, 0, 0.2), -1px -1px 4px 4px rgba(0, 0, 0, 0.1);
    color: white;

    & > a {
      color: white;
      text-decoration: none;
      font-weight: 700;
    }
  }

  &__pointer {
    height: 20px;
    width: 20px;
    background-color: #FE3466;
    margin: 0 auto;
    transform: rotate(45deg);
    border-radius: 0 0 2px 0;
    margin-top: -10px;
    position: relative;
    left: 35px;

    &.mirrored {
      top: -90px;
    }
  }
}