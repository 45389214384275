.play-btn {
    display: flex;
    background-color: transparent!important;
    border-color: transparent!important;
    padding: 0!important;
    outline: none!important;
    box-shadow: none!important;
    cursor: pointer;
    align-items: center;

    img {
        width: 60px;
        height: 20px;
    }
}

.dropdown-item {

    & > .player-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .place {
            width: 92px;
            font-family: Poppins, sans-serif;
            font-size: 15px;
        }

        .dropdown {
            position: static;
        }

        .player-section {
            width: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .icon-caption {
            text-align: center;
            width: 62px;
            color: #717171;
            font-family: Poppins, sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
        }

        .players {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 138px;
            &.short {
                display: block;
                width: 60px;
            }

            button {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .caption-container {
            display: flex;
            width: 138px;
            justify-content: space-between;
            cursor: auto;
            margin-bottom: 3px;
            &.short {
                width: 60px;
            }
        }

        .btn-icon-container {
            display: inline;
        }
    }
}

.dot {
    height: 10px;
    margin-right: 8px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 2px;
}

.right-semi-dot {
    height: 10px;
    margin-right: 8px;
    width: 5px;
    border-radius: 0 5px 5px 0;
    display: inline-block;
    margin-bottom: 2px;
}

.left-semi-dot {
    height: 10px;
    width: 5px;
    border-radius: 5px 0 0 5px;
    display: inline-block;
    margin-bottom: 2px;
}

.box-dropdown-btn {
    position: absolute;
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% ;
    top: 80px;
    right: 230px;
    background: none !important;
    border: none !important;
    padding: 0 !important;

    &:hover {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.box-play-btn {
    width: 28px;
    height: 28px;
    background: black;
    border: 1px solid #FFFFFF;
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;

    &:hover {
        background: rgb(253, 76, 0);
    }

    &__icon {
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 11px solid #FFFFFF;
        border-bottom: 6px solid transparent;
        margin-left: 2px;
    }
}
.box-play-btn-active {
    background: #FD3000;
}

