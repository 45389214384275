.confirm-delete-modal {
  width: 533px;
  height: 373px;
  box-shadow: 0 0 6px 2px rgba(35, 35, 35, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid #606060;
  border-radius: 28px;
  background-color: #1D2229;
  padding: 28px;

  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }

  &__question {
    font-family: Poppins, sans-serif;
    width: 100%;
    font-weight: 300;
    font-size: 26px;
    color: white;
    text-align: center;
  }

  &__buttons {
    width: 280px;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }

  &__btn {
    border: none;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    text-align: center;
    color: white;
    font-weight: 300;
  }

  &__yes-btn {
    width: 109px;
    height: 42px;
    background-color: #396DC6;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: #10369D;
    }
  }

  &__no-btn {
    width: 109px;
    height: 42px;
    background-color: #525862;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: #B92C2C;
    }
  }
}
