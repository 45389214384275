.sky-boxes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 37px;
  width: 100%;
  margin-top: 4px;
  background-color: #323a45;
  color: white;
  cursor: pointer;
  border-bottom: 5px solid #1a1f25;
}

.sky-boxes-control-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 45%;

  &__btn {
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1a1f25;
    margin-right: 5px;
  }

  &__btn:hover > &__btn-arrow {
    transition: .2s;
    transform: rotate(90deg);
  }

  &__btn-arrow {
    height: 16px;
    width: 16px;
    display: block;
    transition: .2s;
  }

  &__btn-arrow-active {
    height: 16px;
    width: 16px;
    display: block;
    transition: .2s;
    transform: rotate(90deg);
  }

  & > p {
    font-family: Poppins, sans-serif;
    font-size: 18px;
  }

  .control-panel-text {
    margin-bottom: 0;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 34px;
  }
}

.boxes-panel {

  .boxes-loader-overlay {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 100;
    text-align: center;
    padding-top: 130px;
  }

  display: flex;
  justify-content: center;
  border-bottom: 5px solid #1a1f25;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
  background-color: #323a45;
  top: 100px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 15px;
}
