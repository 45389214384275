.confirmation-window {
  position: absolute;
  width: 500px;
  height: 210px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s all;
  text-align: center;
  z-index: 1;
  background-color: white;

  &.visible {
    top: 10px;
  }

  &.hidden {
    top: -220px;
  }

  &__top {
    height: 55px;
    width: 100%;
    background-color: #323b44;
  }

  &__question {
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-weight: 700;
    margin-top: 15px;
  }

  &__buttons {
    width: 72%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 15px;
  }

  &__button {
    background-color: #323b44;
    padding: 8px 0 8px 0;
    width: 100px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-radius: 21px;
    text-align: center;

    &:hover {
      background-color: white;
      color:  #323b44;
      border: 1px solid #323b44;
    }
  }
}